
import './App.css';
import { useState } from 'react';
function App() {
  let myLeads=[]
const ulEl=document.getElementById("ul-el")
const [message,setMessage]=useState("");
const handleChange=event=>{
  event.preventDefault();
   setMessage(event.target.value) 
     
 }
 function ButtonClick(){
  myLeads.push(message)
  setMessage("")
  renderTheLeads()
 
//  we can improce the performence by not letting our ul to repeat again and again
// another method to do this
function renderTheLeads(){
  let listItems=""
  for (let i=0;i<myLeads.length;i++){
    /* let anchor="<a target='_blank'href="+myLeads[i]+">"+myLeads[i] +"</a>" another method of doing line 23 and 24
     listItems+="<li>"+anchor+"</li>"*/
     listItems=`<li>
                  <a href='${myLeads[i]}'target=_blank>
                  ${myLeads[i]}
                  </a>
                 </li`
    console.log(listItems)
  }
  ulEl.innerHTML+=listItems
}
// we can also use this method
// function renderTheLeads(){
 // for(let i=0;i<myLeads.length;i++){
  //   // ulEl.innerHTML+="<li>"+myLeads[i]+"</li> "
  //   const li=document.createElement("li")
  //   li.textContent=myLeads[i]
  //   ulEl.append(li)
  // }
// }
 
 
   
  



  
 }

 


  return (
    <>
    <div>
      <input onChange={handleChange}  value={message}  type="text"id="input-el" />
      <button onClick={ButtonClick} id="btn-el">Save input</button>
      <ul id="ul-el">

      </ul>
    </div>
    </>
  );
}

export default App;
